.indoor_amenities_section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 35px;
}

.party {
  display: flex;
  align-items: center;
}
.party img {
  height: 77px;
  width: 90px;
}
.party h3 {
  font-size: 16px;
}
@media (max-width: 426px) {
  .indoor_amenities_section {
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
  }
  .party {
    display: flex;
    align-items: center;
  }
  .party img {
    height: 50px;
    width: 50px;
  }
  .party h3 {
    font-size: 9px;
  }
}
