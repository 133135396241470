.peace_hero_img {
  width: 100%;
  height: 80vh;
}
.peace_hero_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overView_section {
  display: flex;
  justify-content: space-evenly;
  height: 500px;
  align-items: center;
}
.overView_text {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 17px;
}
.overView_image {
  width: 400px;
  height: 400px;
}
.overView_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: drop-shadow(1px 3px 10px gray);
}
.plan_heading h2 {
  text-align: center;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
}
.plan_images {
  width: 100%;
  height: 100%;
}
.plan_images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gym {
  display: flex;
  align-items: center;
}
.gym img {
  width: 100px;
  height: 100px;
}
.amenities_heading {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.amenities_list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.amenities_photo_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 40px;
}
.lobby {
  width: 300px;
  height: 300px;
}
.lobby img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.mesmerising_heading {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin-top: 25px;
}
.mesmerising_images {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.mesmerising_image {
  width: 325px;
  height: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mesmerising_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.mesmerising_image h3 {
  font-size: 25px;
}
@media (max-width: 426px) {
  .peace_hero_img {
    width: 100%;
    height: 100%;
  }
  .overView_section {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 15px;
    height: auto;
  }
  .overView_text {
    width: 100%;
    font-size: 12px;
  }
  .overView_image {
    width: 100%;
    height: 100%;
  }
  .plan_heading h2 {
    font-size: 25px;
  }
  .amenities_heading h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 12px;
  }
  .gym img {
    width: 60px;
    height: 60px;
  }
  .mesmerising_heading {
    height: 50px;
    margin-top: 25px;
    font-size: 16px;
  }
  .mesmerising_image {
    width: 270px;
    height: 251px;
  }
  .lobby {
    width: 270px;
    height: 250px;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .overView_section {
    width: 100%;
    padding: 10px;
    gap: 15px;
    align-items: center;
    height: auto;
  }
  .overView_text {
    width: 100%;
    font-size: 14px;
  }
  .overView_image {
    width: 700px;
    height: 400px;
  }
}
