.slider {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  position: relative;
}
.slider-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}
.slider-image {
  min-width: 100%;
  height: 100%;
}
.slider-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: block;
}
@media (max-width: 426px) {
  .slider {
    height: 100%;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .slider {
    height: 100%;
  }
}
