.royal_about_heading {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.royal_about_wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.royal_about_text {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 17px;
}
.royal_image_side {
  width: 500px;
  height: 500px;
}
.royal_image_side img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.royal_master_plane_heading {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.royal_master_plan_image {
  height: 90vh;
  width: 100%;
}
.royal_master_plan_image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.royal_amenities_heading {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.royal_external_amenities {
  display: flex;
  justify-content: space-around;
}
.external_amenity {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.external_amenity img {
  height: 100px;
  width: 100px;
}
.royal_internal_amenities_heading {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.royal_internal_amenities {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.internal_amenities {
  gap: 37px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.internal_amenity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.internal_amenity img {
  height: 100px;
  width: 100px;
}
.royal_images_heading {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.royal_images {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  padding: 10px;
}
.royal_image {
  height: 450px;
  width: 450px;
}
.royal_images img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.royal_images img:hover {
  transform: scale(1.1);
}
@media (max-width: 426px) {
  .royal_about_heading {
    height: 60px;
    font-size: 14px;
  }
  .royal_about_wrapper {
    flex-direction: column;
  }
  .royal_about_text {
    width: 100%;
    padding: 5px;
    font-size: 12px;
  }
  .royal_image_side {
    width: 100%;
    height: 100%;
  }
  .royal_master_plane_heading {
    height: 50px;
    font-size: 17px;
  }
  .royal_amenities_heading {
    height: 80px;
    font-size: 15px;
  }
  .royal_master_plan_image {
    height: 100%;
  }
  .royal_external_amenities {
    flex-wrap: wrap;
  }
  .royal_internal_amenities {
    flex-wrap: wrap;
  }
  .royal_internal_amenities_heading {
    width: 100%;
    font-size: 16px;
    height: 80px;
  }
  .internal_amenities {
    width: 100%;
    flex-wrap: wrap;
  }
  .external_amenity img {
    height: 80px;
    width: 80px;
  }
  .internal_amenity img {
    height: 80px;
    width: 80px;
  }
  .royal_image {
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .royal_about_heading {
    height: 60px;
    font-size: 14px;
  }
  .royal_about_wrapper {
    flex-direction: row;
  }
  .royal_about_text {
    width: 100%;
    padding: 5px;
    font-size: 12px;
  }
  .royal_image_side {
    width: 100%;
    height: 100%;
  }
  .royal_master_plane_heading {
    height: 50px;
    font-size: 17px;
  }
}
