.footer_main_wrapper {
  display: flex;
  background-color: black;
  align-items: center;
  height: 400px;
  justify-content: space-around;
  margin-top: 50px;
  color: white;
}
.icons {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  cursor: pointer;
}
.social_media {
  display: flex;
  gap: 20px;
}
.contain_side {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}
.footer_logo img {
  height: 180px;
  object-fit: cover;
}
.map_section {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.map {
  width: 100%;
  height: 250px;
}
.map iframe {
  width: 100%;
  height: 100%;
}
a {
  color: white;
  text-decoration: none;
}
@media (max-width: 426px) {
  .footer_main_wrapper {
    flex-direction: column;
    gap: 15px;
  }
  .icons {
    width: 40px;
    height: 40px;
    font-size: 23px;
  }
  .social_media {
    display: flex;
    gap: 20px;
  }
  .contain_side {
    width: 100%;
    height: auto;
    align-items: center;
  }
  .map_section {
    width: 100%;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .footer_main_wrapper {
    padding: 10px;
  }
  .gallery_section {
    margin-top: 0px;
  }
}
