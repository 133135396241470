.karam_about_section {
  display: flex;
  align-items: center;
  height: 600px;
  justify-content: space-evenly;
}
.karam_about_text_section {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.karam_about_image {
  width: 500px;
  height: 500px;
}
.karam_about_image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_mission_section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.karam_mission_image {
  width: 500px;
  height: 400px;
}
.karam_mission_image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_mission_text_section {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.k_master_plan_heading {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}
.k_master_plan_heading h2 {
  font-size: 35px;
  font-weight: 800;
}
.k_master_plan_image {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}
.k_master_plan_image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_amenities_section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.karam_amenities_section h2 {
  font-size: 35px;
}
.k_amenities_link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: xx-large;
  gap: 70px;
  font-weight: 600;
}
.nested_link {
  text-decoration: none;
  color: black;
}
.karam_chand_images {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
  margin-top: 40px;
}
.karam_chand_images img {
  border-radius: 30px;
}
.karam_gallery_img1 {
  width: 450px;
  height: 450px;
}
.karam_gallery_img1 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_gallery_img2 {
  height: 100%;
}
.karam_gallery_img3 {
  width: 400px;
  height: 450px;
}
.karam_gallery_img3 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_gallery_img5 {
  height: 550px;
  width: 550px;
}
.karam_gallery_img5 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_gallery_img6 {
  height: 500px;
  width: 100%;
}
.karam_gallery_img6 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_gallery_img7 {
  height: 500px;
  width: 500px;
}
.karam_gallery_img7 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.karam_gallery_img9 {
  width: 500px;
  height: 500px;
}
.karam_gallery_img9 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
@media (max-width: 426px) {
  .karam_about_section {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-top: 25px;
  }
  .karm_about_heading {
    font-size: 13px;
    text-align: center;
  }
  .karam_about_text_section {
    width: 100%;
    gap: 10px;
  }
  .karam_about_image {
    width: 100%;
    height: 100%;
  }
  .karam_about_text {
    font-size: 12px;
  }
  .karam_mission_section {
    flex-direction: column;
    padding: 5px;
  }
  .karam_mission_image {
    width: 100%;
    height: 100%;
  }
  .karam_mission_text_section {
    width: 100%;
    gap: 10px;
  }
  .karam_mission_text {
    font-size: 12px;
  }
  .karam_mission_heading {
    justify-content: center;
    display: flex;
  }
  .k_master_plan_heading {
    margin-top: 0px;
  }
  .k_master_plan_heading h2 {
    font-size: 23px;
    font-weight: 800;
  }
  .k_master_plan_image {
    height: 100%;
  }
  .k_amenities_link {
    font-size: large;
    padding: 10px;
  }
  .karam_amenities_section {
    height: 77px;
  }
  .karam_amenities_section h2 {
    font-size: 23px;
  }
  .karam_chand_images {
    margin-top: 35px;
  }
  .karam_gallery_img1 {
    width: 100%;
    height: 100%;
  }
  .karam_gallery_img2 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img2 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .karam_gallery_img3 {
    width: 100%;
    height: 100%;
  }
  .karam_gallery_img4 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img4 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .karam_gallery_img5 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img6 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img7 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img8 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img8 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .karam_gallery_img9 {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .karam_about_section {
    height: auto;
    margin-top: 25px;
    padding: 10px;
  }
  .karm_about_heading {
    font-size: 13px;
    text-align: center;
  }
  .karam_about_text_section {
    width: 100%;
    gap: 25px;
  }
  .karam_about_image {
    width: 100%;
    height: 100%;
  }
  .karam_about_text {
    font-size: 12px;
  }
  .karam_mission_section {
    flex-direction: row;
    padding: 5px;
  }
  .karam_mission_image {
    width: 100%;
    height: 100%;
  }
  .karam_mission_image img {
    height: 250px;
  }
  .karam_mission_text_section {
    width: 100%;
    gap: 10px;
    padding: 10px;
  }
  .karam_mission_text {
    font-size: 12px;
  }
  .karam_mission_heading {
    justify-content: center;
    display: flex;
  }
  .k_master_plan_heading {
    margin-top: 0px;
  }
  .k_master_plan_heading h2 {
    font-size: 23px;
    font-weight: 800;
  }
  .k_master_plan_image {
    height: 100%;
  }
  .karam_chand_images {
    margin-top: 35px;
  }
  .karam_gallery_img1 {
    width: 100%;
    height: 100%;
  }
  .karam_gallery_img2 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img2 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .karam_gallery_img3 {
    width: 100%;
    height: 100%;
  }
  .karam_gallery_img4 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img4 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .karam_gallery_img5 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img6 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img7 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img8 {
    height: 100%;
    width: 100%;
  }
  .karam_gallery_img8 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .karam_gallery_img9 {
    width: 100%;
    height: 100%;
  }
}
